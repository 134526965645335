<template>
	<div class="content">
		<sb-search @onSubmit="onSubmit" @exportExcel="exportExcel" @reset="reset" :flagType="flagType['1']">
		</sb-search>
		<!--     border="1px"   -->
		<table width="100%" style="border-collapse: collapse;">
			<!--     表格头部       -->
			<tr align="center" class="headTr border">
				<th width="10px">
					<span class="headTrContent">
						<div style="padding: 12px 0">
							<el-checkbox v-model="allFlag" @change="selectAll"> </el-checkbox>
						</div>
					</span>
				</th>
				<th v-for="(item,index) in column" :key="index">
					<div class="headTrContent" style="padding: 12px 0">
						{{item.title}}
					</div>
				</th>
			</tr>
			<!--     表格主体      -->
			<tbody v-for="(item,index) in orderList" :key="index" style="margin: 10px 0">
				<tr style="background: #EAF8FF" class="border">
					<td style="padding: 0 10px 0 5px">
						<div class="onePadding">
							<!--                            <el-checkbox v-model="item.flag" @change="oneChange($event,index)"></el-checkbox>-->
							<el-checkbox v-model="item.flag"></el-checkbox>
						</div>
					</td>
					<td colspan="10">
						<div class="onePadding">
							<span class="margin" v-if="item.orderNum">任务ID：{{item.orderNum}}</span>
							<span class="margin" v-if="item.beginTime">预计下单时间：{{item.beginTime}}点</span>
							<span class="margin">
								商品编号：{{item.goodsNo}}
								<i class="el-icon-link" @click="goHref(item.goodsLink)" style="cursor: pointer"></i>
							</span>
							<span class="margin">好评：{{item.appraiseName}}</span>
							<span class="margin" v-if="!item.appraiseStatus">
								<el-link type="primary" @click="pushAppraise(item)"
									v-if="item.status == 3&&!item.appraiseStatus">去发布</el-link>
							</span>
							<span class="flag">
								<el-popover placement="top" width="400" v-model="item.showPopver">
									<div>
										<el-form label-width="80px" size="mini">
											<el-form-item label="标签" class="radioBox">
												<el-radio-group v-model="flagForm.pushFlag">
													<el-radio :label="flagItem.key" :value="flagItem.value"
														v-for="(flagItem,flagIndex) in flagType[String(item.platformId)]"
														:key="flagIndex">
														<!--                                              {{flagItem.value}}-->
														<i class="el-icon-s-flag" :style="{color: flagItem.color}"></i>
													</el-radio>
												</el-radio-group>
											</el-form-item>
											<el-form-item label="备注">
												<el-input type="textarea" :rows="2" placeholder="标记信息作为你对这笔订单的标记记录"
													v-model="flagForm.pushNotes">
												</el-input>
											</el-form-item>
											<el-form-item style="display: flex;justify-content: right">
												<el-button type="primary" @click="submitFlagForm(index);">确定</el-button>
											</el-form-item>
										</el-form>

									</div>
									<span v-if="flagType[String(item.platformId)]" slot="reference">
										<span v-for="(flagItem,flagIndex) in flagType[String(item.platformId)]"
											:key="flagIndex">
											<el-tooltip effect="dark" :content="orderList[index].pushNotes"
												placement="top">
												<i style="font-size: 16px" class="el-icon-s-flag"
													@click="changeFlag(item,index)" :style="{color: flagItem.color}"
													v-if="flagItem.key == item.pushFlag">
												</i>
											</el-tooltip>
										</span>
									</span>


								</el-popover>
							</span>
						</div>
					</td>
				</tr>
				<tr class="border">
					<td>
					</td>
					<td class="td " style="padding-left:  0">
						<div class="base">
							<el-image style="width: 50px; height: 50px" :src="item.goodsPicture" fit="fit">
							</el-image>
							<div class="baseInfo">
								<div style="display: flex;align-items: center;margin-bottom: 3px;">
									<el-tag size="mini" style="margin-right: 5px">{{item.platformName}}</el-tag>
									<span class="overflow" style="width: 140px">{{item.shopName}}</span>
								</div>
								<div v-if="item.accountName" style="margin-bottom: 3px;">
									买家旺旺：<span>{{item.accountName}}</span>
								</div>
								<div v-if="item.orderNo" style="margin-bottom: 3px;">
									订单编号：{{item.orderNo}}
								</div>
							</div>
						</div>
					</td>
					<!--          入店途径          -->
					<td class="td border" align="center">
						<div class="unit">
							<div>{{item.entranceName }}：</div>
							<div class="overflow" style="width: 140px">
								{{item.keywords}}
							</div>
						</div>
					</td>

					<td class="td border" align="center">
						<span>{{item.copingPrice||''}}</span>
					</td>
					<td class="td border" align="center">
						<span>{{item.serviceFee}}</span>
					</td>
					<td class="td border" align="center">
						<span>平台返款</span>
					</td>
					<td class="td border" align="center">
						<span>{{item.taskTypeName}}</span>
					</td>
					<td class="td border" align="center">
						<el-tag :color="item.statusStyle" size="mini" style="color: #444">
							{{item.statusLabel}}
						</el-tag>
					</td>
					<td class="td border" align="center">
						<div class="active">
							<!--                            <div>-->
							<!--                                <el-link type="primary">买家拉黑</el-link>-->
							<!--                            </div>-->
							<!--                            <div>-->
							<!--                                <el-link type="primary">确认发货</el-link>-->
							<!--                            </div>-->
							<!--                            <div>-->
							<!--                                <el-link type="primary">一键验号</el-link>-->
							<!--                            </div>-->
							<!--                            <div>-->
							<!--                                <el-link type="primary">发布评价</el-link>-->
							<!--                            </div>-->
							<!--                            <div>-->
							<!--                                <el-link type="primary">申诉买家</el-link>-->
							<!--                            </div>-->
						</div>
					</td>
				</tr>

			</tbody>
		</table>
		<!-- 分页-->
		<div style="height: 65px">
			<el-pagination style="float: right;margin-top: 20px" @size-change="handleSizeChange"
				@current-change="handleCurrentChange" :page-sizes="[50, 200, 800, 1200]" layout="total, pager,  sizes"
				:total="total">
			</el-pagination>
		</div>

		<add-appraise ref="addAppraise" @success="addSuccess"></add-appraise>
	</div>
</template>
<script>
	import {
		ORDERLIST,
		ImgUrl
	} from "../../api/base";
	import SbSearch from './components/searForm';
	import 'vue-virtual-scroller/dist/vue-virtual-scroller.css'
	import AddAppraise from '../evaluate/components/addAppraise'
	const column = [{
			title: '基础信息',
		},
		{
			title: '入店途径',
		},
		{
			title: '垫付金额（元）',
		},
		{
			title: '服务费（元）',
		},
		{
			title: '买家垫付',
		},
		{
			title: '任务类型',
		},
		{
			title: '状态',
		},
		{
			title: '操作',
		},
	]

	export default {
		name: "orderInfo",
		data() {
			return {
				flagForm: {
					orderId: '',
					pushFlag: '',
					pushNotes: '',
				},
				showPopver: false,
				flagType: {
					'1': [{
							color: '#CCCCCC',
							key: 0,
							value: '灰色'
						},
						{
							color: '#FF6666',
							key: 1,
							value: '红色'
						},
						{
							color: '#F2E864',
							key: 2,
							value: '黄色'
						},
						{
							color: '#5CE55C',
							key: 3,
							value: '绿色'
						},
						{
							color: '#4DB3FF',
							key: 4,
							value: '蓝色'
						},
						{
							color: '#E160EF',
							key: 5,
							value: '紫色'
						},
					],
					'3': [{
							color: '#FF6666',
							key: 1,
							value: '红色'
						},
						{
							color: '#F2E864',
							key: 2,
							value: '黄色'
						},
						{
							color: '#5CE55C',
							key: 3,
							value: '绿色'
						},
						{
							color: '#4DB3FF',
							key: 4,
							value: '蓝色'
						},
						{
							color: '#E160EF',
							key: 5,
							value: '紫色'
						},
					],
				},
				allFlag: false,
				loading: false,
				column,
				falgColor: '',
				orderList: [],
				taskList: [],
				listForm: {
					pageIndex: 1,
					pageSize: 50,
					beginDate: '',
					endDate: '',
				},
				total: 0,
			}
		},
		computed: {
			selectList() {
				const arr = [];
				this.orderList.forEach(item => {
					if (item.flag) {
						arr.push(item)
					}
				});
				return arr;
			},
			selectId() {
				const idList = [];
				this.orderList.forEach(item => {
					if (item.flag) {
						idList.push(item.id)
					}
				})
				return idList
			},
		},
		methods: {
			addSuccess() {
				this.getList()
			},
			pushAppraise(item) {
				this.$refs.addAppraise.open(item)
			},
			submitFlagForm(index) {
				const {
					orderId,
					pushFlag,
					pushNotes
				} = this.flagForm
				this.$axios.put(`/order/update/push/flag/${orderId}`, {
					pushFlag,
					pushNotes
				}).then(resp => {
					if (resp.code == 200) {
						// this.orderList[index].pushFlag = this.flagForm.pushFlag;
						this.$set(this.orderList[index], 'pushFlag', this.flagForm.pushFlag)
						this.$set(this.orderList[index], 'pushNotes', this.flagForm.pushNotes)
						this.$set(this.orderList[index], 'showPopver', false)
						console.log(this.orderList[index].showPopver)
						this.flagForm.pushFlag = '';
						this.flagForm.pushNotes = '';
						this.$mes({
							message: resp.msg
						})
					}
				})
			},
			changeFlag(row) {
				this.showPopver = true;
				this.flagForm = {
					orderId: row.id,
					pushFlag: row.pushFlag,
					pushNotes: row.pushNotes,
				}
			},
			goHref(url) {
				// console.log(url)
				window.open(url)
			},
			handleSizeChange(val) {
				this.listForm.pageSize = val;
				this.getList()
			},
			handleCurrentChange(val) {
				this.listForm.pageIndex = val;
				this.getList()
			},

			formatJson: function(filterVal, jsonData) {
				return jsonData.map(v => filterVal.map(j => {
					return v[j]
				}))
			},
			exportExcel() {
				if (this.selectList.length == 0) {
					return this.$mes({
						message: '请至少选择一条数据',
						type: 'warning'
					})
				}
				const loading = this.$loading();
				import('@/tools/Export2Excel').then(excel => {
					const tHeader = ['订单编号', '买家旺旺', '平台', '店铺', '商品编号', '商品套餐', '应付金额', '实付金额', '服务费', '预计下单时间',
						'任务状态 ',
					]
					const filterVal = ['orderNo', 'accountName', 'platformName', 'shopName', 'goodsNo',
						'goodsSeamen', 'totalPayablePrice', 'copingPrice', 'serviceFee', 'beginTime',
						'statusLabel',
					]
					const list = this.selectList;
					const data = this.formatJson(filterVal, list);
					excel.export_json_to_excel({
						header: tHeader,
						data,
						filename: '订单数据',
						autoWidth: true,
						bookType: 'xlsx'
					})
					loading.close()
				})
			},
			reset(form) {
				for (let item in form) {
					this.listForm[item] = form[item]
				}
				this.listForm.pageIndex = 1;
				this.getList()

			},
			onSubmit(form) {
				for (let item in form) {
					this.listForm[item] = form[item]
				}
				this.listForm.pageIndex = 1;
				this.getList()
			},
			selectAll(flag) {
				const orderList = this.orderList.concat()
				this.orderList = [];
				orderList.map(item => {
					item.flag = flag;
				})
				this.orderList = orderList;
				// this.$forceUpdate()
			},
			// oneChange(flag,index){
			// this.orderList[index].flag = flag;
			// console.log(this.orderList[index].flag)
			// },
			checkChange(flag) {
				console.log(flag)
			},
			onChange: (selectedRowKeys, selectedRows) => {
				console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows);
			},
			onSelect: (record, selected, selectedRows) => {
				console.log(record, selected, selectedRows);
			},
			onSelectAll: (selected, selectedRows, changeRows) => {
				console.log(selected, selectedRows, changeRows);
			},
			formmateTime(time) {
				let nowDate = new Date(time);
				let year = nowDate.getFullYear();
				let month = nowDate.getMonth() + 1 < 10 ? "0" + (nowDate.getMonth() + 1) : nowDate.getMonth() + 1;
				let day = nowDate.getDate() < 10 ? "0" + nowDate.getDate() : nowDate.getDate();
				return year + "-" + month + "-" + day;
			},
			filterStatus(status) {
				let statusName = ''
				switch (status) {
					case 1:
						statusName = '待评价'
						break;
					case 2:
						statusName = '待审核'
						break;
					case 3:
						statusName = '已通过'
						break;
					case 4:
						statusName = '不通过'
						break;
				}
				return statusName
			},
			getList() {
				this.allFlag = false;
				const loading = this.$loading();
				this.$axios.get(ORDERLIST, this.listForm).then(resp => {
					this.loading = false;
					loading.close()
					if (resp.code == 200) {
						const orderList = resp.data.data;
						this.total = resp.data.totalRow;
						orderList.forEach(item => {
							item['flag'] = false;
							//评价判断
							item.appraiseName = this.filterStatus(item.appraiseStatus)
							item.goodsPicture = ImgUrl + item.goodsPicture
						})
						this.orderList = orderList;
					}
				}).catch(() => {
					loading.close()
				})
			}
		},
		components: {
			SbSearch,
			AddAppraise,
		},
		mounted() {
			const newData = this.formmateTime(new Date())
			this.listForm.beginDate = newData;
			this.listForm.endDate = newData;
			this.getList()
		}
	}
</script>

<style scoped lang="less">
	@borderColor: 1px solid rgb(224, 224, 224);

	.radioBox {
		/deep/ .el-radio {
			margin-right: 5px;
		}
	}

	.unit {}

	.overflow {
		display: inline-block;
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
	}

	.border {
		border: @borderColor;
		padding: 5px 0;

	}

	.margin {
		margin-right: 20px;
	}

	.onePadding {
		margin: 8px 0;

		.flag {
			float: right;
			margin-right: 10px;
			cursor: pointer;
		}
	}

	.base {
		display: flex;
		align-items: center;
		font-size: 12px;
		padding: 5px 0;

		.baseInfo {
			margin-left: 8px;
		}

	}

	.content {
		background: white;
		padding: 20px;

		.headTr {
			background: #f5f5f5;

		}

		tbody::before {
			content: '';
			display: block;
			height: 20px;
			width: 105%;

		}

		.active {
			font-size: 12px;
			display: flex;
			flex-direction: column;
			align-items: center;
		}

		.td {
			padding: 0 8px;
		}
	}
</style>
